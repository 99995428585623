<template>
 <main id="main" class="site-main">
 <Feed :items="postsnfl" name="NFL News" />
 <Feed :items="postscollfootball" name="College Football News" />
 <Feed :items="postsnba" name="NBA News"/>
 <Feed :items="postscollege" name="College Basketball News" />
 <Feed :items="postsmlb" name="MLB News" />
 </main>
</template>
<script>
import Feed from './Components/NLSE/Feed.vue'
import { core } from '../../config/pluginInit'
import RSSParser from 'rss-parser'
export default {
	name: 'Rssfeed',
	components: {
		Feed
	},
	created() {
		this.getData()
	},
	mounted () {
    core.index()
	},
	methods: {
		async getData() {
			try {
				const response = await this.$http.get('https://dev-rss.nlse.com/nba/rss/')
				const parser = new RSSParser()
          		parser.parseString(response.data, (err, parsed) => {
            		this.loading = false
           			if (err) {
						// console.log(err) 
					} else {
							this.postsnba = parsed.items.slice(0, 10)
							// console.log(this.postsnba)						
						}
					})
			} catch (error) {
				// console.log(error)
			}
			try {
				const response = await this.$http.get('https://dev-rss.nlse.com/mlb/rss/')
				const parser = new RSSParser()
          		parser.parseString(response.data, (err, parsed) => {
            		this.loading = false
           			if (err) {
						// console.log(err) 
					} else {
							this.postsmlb = parsed.items.slice(0, 10)
							// console.log(this.posts)
						}
					})
			} catch (error) {
				// console.log(error)
			}
			try {
				const response = await this.$http.get('https://dev-rss.nlse.com/nfl/rss/')
				const parser = new RSSParser()
          		parser.parseString(response.data, (err, parsed) => {
            		this.loading = false
           			if (err) {
						// console.log(err) 
					} else {
							this.postsnfl = parsed.items.slice(0, 10)
							// console.log(this.posts)
						}
					})
			} catch (error) {
				// console.log(error)
			}
			try {
				const response = await this.$http.get('https://dev-rss.nlse.com/college-basketball/rss/')
				const parser = new RSSParser()
          		parser.parseString(response.data, (err, parsed) => {
            		this.loading = false
           			if (err) {
						// console.log(err) 
					} else {
							this.postscollege = parsed.items.slice(0, 10)
							// console.log(this.postscollege)
						}
					})
			} catch (error) {
				// console.log(error)
			}
			try {
				const response = await this.$http.get('https://dev-rss.nlse.com/soccer/rss/')
				const parser = new RSSParser()
          		parser.parseString(response.data, (err, parsed) => {
            		this.loading = false
           			if (err) {
						// console.log(err) 
					} else {
							this.postssoccer = parsed.items.slice(0, 10)
							// console.log(this.postssoccer)
						}
					})
			} catch (error) {
				// console.log(error)
			}
			try {
				const response = await this.$http.get('http://localhost:3000/college-football/rss/')
				const parser = new RSSParser()
          		parser.parseString(response.data, (err, parsed) => {
            		this.loading = false
           			if (err) {
						// console.log(err) 
					} else {
							this.postscollfootball = parsed.items.slice(0, 10)
						}
					})
			} catch (error) {
				// console.log(error)
			}
		}
	},
	data() {
    return {
	  postsnba: [],
	  postsmlb: [],
	  postsnfl: [],
	  postscollege: [],
	  postssoccer: [],
	  postsboxing: [],
	  postscollfootball: [],
	  values: null
    }
  }
}
</script>
