<template>
  <section id="iq-suggestede">
    <b-container>
      <b-row>
        <b-col sm="12" class="overflow-hidden">
          <div
            class="iq-main-header d-flex align-items-center justify-content-between"
          >
            <h2 class="iq-title text-capitalize">
              {{ name }}
            </h2>
          </div>
          <div class="favorites-contens">
            <Slick
              class="favorites-slider list-inline  row p-0 mb-0"
              ref="dSlick"
              :option="sliderOption"
              v-if="items.length"
            >
              <li
                class="slide-item"
                v-for="(item, index) in items"
                :key="index"
              >
                <v-card
                  color="rgb(50, 50, 50, 1.0)"
                  elevation="24"
                  class="rounded-card"
                >
                  <div class="blog-item">
                    <v-card-text>
                      <div style="color:white">Yahoo! Sports</div>
                      <v-card-title>
                        <a
                          :href="item.link"
                          style="color:white"
                          target="_blank"
                        >
                          <strong> {{ item.title.length > 70 ? item.title.substring(0,70)+"..." : item.title  }} </strong>
                        </a>
                      </v-card-title>
                      <div style="color:white">{{ item.pubDate }}</div>
                    </v-card-text>
                  </div>
                </v-card>
              </li>
            </Slick>
            <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
              <symbol
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2 2"
                width="4px"
                height="4px"
                id="circle"
                fill="none"
                stroke="currentColor"
              >
                <circle r="2" cy="2" cx="2" id="test"></circle>
              </symbol>
            </svg>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: 'Feed',
  components: {},
  props: {
    items: Array,
    name: String
  },
  data() {
    return {
      sliderOption: {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: '<div class="slick-prev slick-arrow"><i class="fa fa-chevron-left"></i></div>',
        nextArrow: '<div class="slick-next slick-arrow"><i class="fa fa-chevron-right"></i></div>',
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  methods: {
    next() {
      this.$refs.dSlick.next()
    },
    prev() {
      this.$refs.dSlick.prev()
    }
  }
}
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.rounded-card {
  border-radius: 10px;
}

.blog-item {
  position: relative;
  height: 200px;
  /*overflow-y: scroll;*/
}

.blog-post-title {
  color: #f2b636;
  font-size: 18px;
  margin-top: 18px;
  margin-bottom: 15px;
}

.blog-post-info p,
.post-event a {
  font-size: 14px;
}

.blog-post-info p {
  margin-bottom: 14px;
}

.blog-category {
  position: relative;
  padding-left: 15px;
  margin-left: 10px;
}

.blog-category::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  height: 12px;
  width: 2px;
  background-color: #969696;
}

.blog-btn {
  padding: 10px 20px;
}

.blog-post-image {
  display: block;
  overflow: hidden;
  position: relative;
}

.blog-post-image::after {
  content: "";
  width: 0;
  height: 4px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -o-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: #f2b636;
}

.blog-item:hover .blog-post-image::after {
  width: 100%;
  left: 0;
  right: auto;
}

.blog-post-image img {
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.blog-item:hover .blog-post-image img {
  width: 100%;
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}

.pagination li {
  margin: 0 5px;
}

.pagination li a {
  height: 40px;
  width: 40px;
  display: flex;
  vertical-align: top;
  background: #314584;
  color: #fff;
  border: #314584;
  justify-content: center;
  align-items: center;
}

.page-item.active .page-link,
.page-item:hover .page-link {
  background: #f2b636;
  border: #f2b636;
  color: #fff;
}

.blog-post-info {
  padding: 0 20px 10px;
}

.blog-read-more {
  position: absolute;
  bottom: -30px;
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  font-size: 20px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #314584;
  color: #314584;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
}

.blog-item:hover .blog-read-more {
  background-color: #f2b636;
  border: 2px solid #f2b636;
  color: #fff;
}
</style>
